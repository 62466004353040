@import 'src/styles/mixins';

.heading {
  @include text(48px, 300, 48px);
  margin-bottom: 36px;

  span {
    font-weight: 800;
  }

  @include vp-435 {
    @include text(36px, 300, 38px);
    max-width: 270px;
    margin-bottom: 20px;
  }
}

.objects {
  margin-bottom: 36px;
}

.mobileAds {
  display: none;

  @include vp-767 {
    display: block;
  }
}
