@import 'src/styles/variables';
@import 'src/styles/mixins';

.slider {
  display: flex !important;
  flex-direction: column-reverse;
  gap: 4px;

  width: 100%;
  position: relative;
  margin-bottom: 24px;

  @include vp-435 {
    display: none !important;
  }
}

.sliderDefault {
  display: flex !important;
  flex-direction: column-reverse;
  gap: 4px;

  width: 100%;
  position: relative;
  margin-bottom: 24px;

  @include vp-435 {
    margin-bottom: 42px;
  }
}

.grid {
  gap: 16px;
  display: none;

  &Content {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 42px;
  }

  @include vp-435 {
    display: grid;
  }
}

.cards {
  flex-direction: column-reverse;
  gap: 4px;
  display: none !important;
  position: relative;
  margin-bottom: 24px;

  @include vp-435 {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 42px;
  }
}

.title {
  position: relative;

  display: inline-flex;
  align-items: center;
  font-family: $manrope;
  @include text(24px, 800, 24px);

  @include vp-435 {
    @include text(18px, 800, 24px);
  }
}

.noButton {
  margin-bottom: 72px;

  @include vp-435 {
    margin-bottom: 42px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &Cards {
    display: none;

    @include vp-435 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}

.buttons {
  display: flex;
  gap: 8px;
}

.prev {
  transform: rotate(180deg);
}

.more {
  margin-bottom: 72px;
  display: flex;
  justify-content: center;

  @include vp-435 {
    margin-bottom: 42px;
  }
}

.buttonMobile {
  text-align: right;
  padding: 0 0 0 32px;
  border-radius: 0;
}

.big {
  @include vp-435 {
    width: 100% !important;
  }
}

div.column {
  display: none;

  @include vp-435 {
    display: flex;
  }
}
