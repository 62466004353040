@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
  position: relative;
  max-width: 1368px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &Panel {
    display: block;
    border-radius: 16px;
    overflow: hidden;

    &:nth-child(3) {
      @include vp-767 {
        grid-column: span 2;
        width: 100%;
      }
    }

    .title {
      @include text(14px, 700, 20px);
      color: $black;
      margin-top: 4px;
      height: 20px;

      &TwoRow {
        @include text(14px, 700, 20px);
        color: $black;
        margin-top: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 20px;
      }
    }

    .type {
      @include text(10px, 400, 20px);
      color: $gray;

      &TwoRow {
        height: 28px;
        @include text(10px, 400, 14px);
        color: $gray;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: wrap;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.period {
  @include text(10px, 500, 20px);
  text-align: left;
  color: $white;
  margin-bottom: 8px;
}

.top {
  position: relative;
  height: 160px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.bottom {
  display: flex;
  flex-direction: column;
  background-color: $light-gray;
  padding: 12px 16px 16px 16px;
  border-radius: 0 0 16px 16px;

  &Empty {
    height: 20px;

    @include vp-435 {
      display: none;
    }
  }
}

.title {
  color: $white;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.arrowButton {
  margin-top: 10px;
}

.description {
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $gray;
  @include text(10px, 500, 14px);
  margin-top: 4px;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  @include text(12px, 400, 20px);
  margin-top: 8px;
  color: $white;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  background-color: $white;
  border-radius: 50px;
  overflow: hidden;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.XXL {
  width: 100%;
  height: 640px;
  padding: 20px 20px 24px 20px;

  .title {
    @include text(24px, 500, 32px);
    margin-top: 16px;
  }

  @include vp-435 {
    height: 450px;
    width: 349px;

    @media (max-width: 400px) {
      height: 410px;
      width: 318px;
    }

    .gallery {
      border-radius: 0px 0px 32px 32px;
      width: 100%;
      height: 452px;
    }
  }

  .button {
    margin-top: 24px;
  }
}

.cardPanel.XL {
  .type {
    @include text(14px, 500, 20px);
  }

  .title {
    @include text(24px, 500, 20px);
    text-align: left;
  }
}

.XL {
  width: 720px;
  height: 372px;
  padding: 20px 20px 24px 20px;

  .title {
    @include text(24px, 500, 32px);
  }

  .top {
    height: 310px;
  }

  .button {
    margin-top: 24px;
  }

  .text {
    @include text(24px, 500, 32px);
    max-width: 600px;
  }

  @include vp-435 {
    height: 450px;
    width: 350px;
    user-select: none;

    @media (max-width: 400px) {
      height: 410px;
      width: 318px;
    }

    .title {
      @include text(16px, 500, 22px);
    }

    .button {
      margin-top: 16px;
    }
  }
}

.cardPanel.XL {
  @include vp-435 {
    height: fit-content;

    .title {
      margin-top: 4px;
      @include text(16px, 400, 24px);
      height: 26px;
      overflow: hidden;
      display: -webkit-box;
      white-space: wrap;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

.cardPanel.XL,
.cardPanel.M {
  padding: 0;
}

.LX {
  width: 672px;
  height: 372px;
  padding: 20px 20px 24px 20px;

  .title {
    @include text(24px, 500, 32px);

    @include vp-435 {
      @include text(12px, 400, 20px);
    }
  }

  .price {
    @include text(14px, 700, 20px);
    color: $gray;
    bottom: 24px;
  }

  .mode {
    @include text(14px, 500, 20px);
    color: $white;
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;

    @include vp-435 {
      margin-top: 8px;
    }
  }

  .top {
    height: 310px;
  }

  .button {
    margin-top: 24px;

    @include vp-435 {
      margin-top: 16px;
    }
  }

  @include vp-767 {
    height: 268px;
    width: 100%;
    max-width: 672px;
  }
}

.L {
  width: 445px;
  height: 237px;
  padding: 20px 20px 24px 20px;

  .title {
    @include text(12px, 400, 20px);
    color: $white;
  }

  .button {
    margin-top: 12px;
  }

  .price {
    @include text(14px, 700, 20px);
  }

  @include vp-435 {
    width: 100%;
  }
}

.cardPanel.M {
  width: 358px;
  height: fit-content;

  .top {
    height: 208px;
  }

  .title {
    @include text(14px, 700, 20px);
  }
}

.card.SM {
  width: 260px;
  height: 240px;
  padding: 20px;

  .title {
    @include text(16px, 500, 20px);
  }
}

.cardPanel.SM {
  width: 330px;
  height: fit-content;

  @include vp-435 {
    width: 252px;
    min-width: 252px;
  }

  .top {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include vp-435 {
      height: 189px;
    }
  }

  .title {
    @include text(14px, 700, 20px);
  }

  .date {
    @include text(10px, 500, 20px);
  }

  .button {
    margin-top: 16px;
  }
}

.cardPanel.S {
  width: 256px;
  height: fit-content;

  .top {
    height: 192px;
    justify-content: space-between;
  }

  .title {
    @include text(14px, 700, 20px);
    height: 40px;
  }

  .bottomEmpty {
    display: none;
  }
}

.M {
  width: 330px;
  height: 237px;
  padding: 20px;

  .title {
    @include text(16px, 500, 22px);
  }

  .button {
    margin-top: 16px;
  }
}

.S {
  width: 252px;
  height: 280px;

  .button {
    margin-top: 16px;
  }

  .info {
    p {
      @include text(10px, 500, 20px);
      color: $white;
    }
  }
}

.card.XS {
  width: 47%;
  min-height: 200px;
  padding: 20px;

  @media (max-width: 360px) {
    width: 100%;
  }

  .text {
    @include text(10px, 500, 20px);
  }

  .title {
    @include text(16px, 500, 22px);
  }
}

.cardPanel.XS.half {
  width: 47%;
}

.cardPanel.XS {
  width: 222px;

  .top {
    height: 166px;
  }

  @include vp-435 {
    width: 200px;
    max-width: 100%;
  }

  .text {
    @include text(10px, 500, 20px);
  }

  .title {
    @include text(14px, 700, 20px);
    text-align: left;
  }
}

.img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
}

.info {
  width: 100%;
  z-index: 0;
  display: flex;
  justify-content: space-between;

  p {
    color: $white;
  }
}

.tag {
  margin-bottom: 16px;
}

.titleBig {
  @include text(48px, 800, 48px);
  color: $white;

  @include vp-767 {
    @include text(36px, 800, 38px);
  }
}

.price {
  @include text(24px, 700, 20px);
  color: $white;
  white-space: nowrap;
  position: absolute;
  right: 20px;
  bottom: 32px;

  @include vp-435 {
    @include text(16px, 500, 20px);
  }
}

.clickable {
  cursor: pointer;
}

.fullWidth {
  width: 100%;
}

.cardPanel.SM.fullWidth {
  @include vp-435 {
    width: 100%;

    .top {
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
    }
  }
}

.card.SM.fullWidth {
  @include vp-435 {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
  }
}

.card.M.fullWidth {
  @include vp-435 {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
  }
}

.closed {
  color: $white;
  @include text(14px, 700, 20px);
  margin-top: 10px;
}

.cardPanel .closed {
  @include text(12px, 500, 12px);
}

button.buttonSoon {
  margin-top: 8px;
  background-color: transparent;
  color: $white;
  padding: 5px 8px;
  @include text(12px, 500, 20px);
  pointer-events: none;
}

.techClosed {
  background: #6d6d6d8f;
}

.twoRowTitle {
  .title {
    height: 40px;
  }
}

.emptyButton {
  height: 30px;
  margin-top: 16px;
}

.contained {
  .img {
    object-fit: contain;
    top: auto;
    bottom: 0;
    height: auto;
    border-radius: 16px 16px 0 0;
  }
}
