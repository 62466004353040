@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  position: relative;
  margin-bottom: 64px;
  min-height: 384px;

  @include vp-767 {
    min-height: 231px;
  }

  @include vp-435 {
    margin-bottom: 24px;
  }
}

.ads {
  padding: 24px 36px 42px 36px;
  background-color: $light-gray;
  border-radius: 16px;

  @include vp-1024 {
    width: 100vw;
    position: absolute;
    top: 0;
    left: -16px;
    border-radius: 0;
  }

  @include vp-767 {
    padding: 16px 16px 20px 16px;
    left: -16px;
  }
}

.heading {
  @include text(10px, 600, 16px);
  letter-spacing: 2px;
  color: $gray;
  margin-bottom: 28px;

  @include vp-767 {
    margin-bottom: 14px;
  }
}

.row {
  display: flex;
  gap: 16px;
}
