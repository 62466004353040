@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  padding: 36px 32px 26px;
  z-index: 0;
  height: 250px;

  border-radius: 16px;
  margin-bottom: 64px;

  @include vp-767 {
    margin-bottom: 44px;
    padding: 30px;
  }

  @include vp-435 {
    padding: 16px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  @include text(40px, 600, 32px);
  color: $white;

  @include vp-1024 {
    line-height: 44px;
  }

  @include vp-767 {
    @include text(32px, 600, 32px);
  }

  @include vp-435 {
    @include text(24px, 600, 32px);
  }
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background: linear-gradient(
    90.7deg,
    rgba(0, 0, 0, 0.6) 0.6%,
    rgba(0, 0, 0, 0.12) 81.96%
  );
}
