@import 'src/styles/variables';
@import 'src/styles/mixins';

.card {
  padding: 20px;
  height: 274px;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  z-index: 0;
  width: 50%;

  @include vp-1024 {
    height: 165px;
    width: 100%;
  }
}

.img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;
  pointer-events: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;

  @include vp-767 {
    gap: 8px;
  }
}

.title {
  color: $white;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include text(18px, 800, 25px);

  @include vp-767 {
    @include text(16px, 500, 22px);
  }
}

.description {
  overflow: hidden;
  display: -webkit-box;
  white-space: wrap;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $white;
  @include text-14;

  @include vp-767 {
    @include text(12px, 400, 20px);
  }
}
